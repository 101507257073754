import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../constants/style';

const ThankYouPage = styled.div`
  background: ${COLORS.textMain};
  color: ${COLORS.textWhite};
  min-height: 50vh;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function ThankYou() {
  return (
    <ThankYouPage>
      <h1>Thank you for contacting us!</h1>
      <h4>We will get back to you as soon as possible!</h4>
    </ThankYouPage>
  );
}
